import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Navbar,
} from '@blueprintjs/core';

import moment from 'moment';

import TextWithTooltip from '../TextWithTooltip';

import { ResultHeader, Result, MultiSelectFilter, TextFilter, DateRangeFilter } from '../code_tables/ResultComponents';

import {
	loadDetailedStockInventoryItems,
} from '../../api/Warehouses';

const DocumentTypes = {
	'cash-invoice':      { type: 'cash-invoice',      title: 'Blagajniški račun'    },
	'invoice':           { type: 'invoice',           title: 'Faktura'              },
	'credit-note':       { type: 'credit-note',       title: 'Dobropis'             },
	'acquisition':       { type: 'acquisition',       title: 'Prevzem'              },
	'write-off':         { type: 'write-off',         title: 'Odpis zaloge'         },
	'reclamation':       { type: 'reclamation',       title: 'Reklamacija'          },
	'representation':    { type: 'representation',    title: 'Reprezentanca'        },
	'commission':        { type: 'commission',        title: 'Komisija'             },
	'debt-release':      { type: 'debt-release',      title: 'Razdolžnica'          },
	'internal-transfer': { type: 'internal-transfer', title: 'Prenos med skladišči' },
	'delivery-note-delivery': { type: 'delivery-note-delivery', title: 'Dobavnica'        },
	'delivery-note-return':   { type: 'delivery-note-return',   title: 'Vračilo v zalogo' },
};

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		openAddEditItemDialog,
		tableItemsFilters,
		handleFilterChange,
		warehouses,
		items,
		payment_types,
		customers,
		stock_state,
		getDocumentNumberForDocument,
	} = props;
	
	let acquired_quantity_sum = 0;
	let spent_quantity_sum    = 0;
	
	const found_last_stock_data = {};
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title='Urejanje' />
				<ResultHeader columnIdx={2} title='Skladišče' />
				<ResultHeader columnIdx={3} title='Črtna koda' />
				<ResultHeader columnIdx={4} title='Šifra artikla' />
				<ResultHeader columnIdx={5} title='Naziv artikla' />
				<ResultHeader columnIdx={6} title='Št. dokumenta' />
				<ResultHeader columnIdx={7} title='Tip dokumenta' />
				<ResultHeader columnIdx={8} title='Način plačila' />
				<ResultHeader columnIdx={9} title='Dobavitelj' />
				<ResultHeader columnIdx={10} title='Datum' />
				<ResultHeader
					columnIdx={11} title='Prevzeto' cls='text-right' />
				<ResultHeader
					columnIdx={12} title='Izdano' cls='text-right' />
				<ResultHeader
					columnIdx={13} title='Zaloga' cls='text-right' />
				<ResultHeader
					columnIdx={14} title='Cena EM' cls='text-right' />
				<ResultHeader
					columnIdx={15} title='Vred. br. DDV' cls='text-right' />
				<ResultHeader
					columnIdx={16} title='Vred. z DDV' cls='text-right' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_warehouse'
							title_field    ='title'
							items          ={warehouses}
							filtered_keys  ={tableItemsFilters.id_warehouses}
							displayFilter  ={x => true}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_warehouses', values)} />
					</div>
				} />
				<Result columnIdx={3} cls='filters-col' />
				<Result columnIdx={4} cls='filters-col' />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.title}
						onChange={ event => handleFilterChange('title', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.document_number}
						onChange={ event => handleFilterChange('document_number', event) } />
				} />
				<Result columnIdx={7} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='type'
							title_field    ='title'
							items          ={DocumentTypes}
							filtered_keys  ={tableItemsFilters.document_types}
							displayFilter  ={x => true}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('document_types', values)} />
					</div>
				} />
				<Result columnIdx={8} cls='filters-col' />
				<Result columnIdx={9} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.supplier_customer_name}
						onChange={ event => handleFilterChange('supplier_customer_name', event) } />
				} />
				<Result columnIdx={10} cls='filters-col' />
				<Result columnIdx={11} cls='filters-col' />
				<Result columnIdx={12} cls='filters-col' />
				<Result columnIdx={13} cls='filters-col' />
				<Result columnIdx={14} cls='filters-col' />
				<Result columnIdx={15} cls='filters-col' />
				<Result columnIdx={16} cls='filters-col' />
				
				{table_items_sort_ids.map((id, idx) => {
					const item      = table_items[id];
					const item_item = items[item.id_item];
					
					let document = item.document;
					
					const supplier = document === null ? undefined : (customers[document.supplier_id_customer] ?? undefined);
					
					const warehouse     = warehouses[item.id_warehouse];
					const document_date = item.document_date;
					
					const fixed_key = item.id_item + '--' + (warehouse?.id_warehouse ?? 'null');
					
					found_last_stock_data[fixed_key] = stock_state.last_stock_data[fixed_key];
					
					let document_type;
					if (item.document_type == 'invoice') {
						document_type = document === null ? '' : DocumentTypes[document.invoice_type];
					}
					else if (item.document_type == 'stock_acquisition_document') {
						document_type = document === null ? '' : DocumentTypes[document.type];
					}
					else if (item.document_type == 'delivery_note') {
						document_type = document === null ? '' : DocumentTypes['delivery-note-' + document.type];
					}
					if (document_type !== undefined) {
						document_type = document_type.title;
					}
					else {
						document_type = '';
					}
					
					const acquired_quantity = item.acquired_quantity;
					const spent_quantity    = item.spent_quantity;
					const current_stock     = item.current_stock;
					
					acquired_quantity_sum += acquired_quantity;
					spent_quantity_sum    += spent_quantity;
					
					const item_price = current_stock?.item_price ?? 0;
					
					let payment_types_title =
						[...new Set(
							(item?.document?.payments ?? [])
								.map(x => x.id_payment_type)
						)]
							.sort((a, b) => {
								return payment_types[a].ord - payment_types[b].ord;
							})
							.map(id_payment_type => payment_types[id_payment_type].title)
							.join(', ');
					
					// override for non-cash invoices (i.e. business invoices)
					if (item?.document?.invoice_type != 'cash-invoice') {
						payment_types_title = '';
					}
					
					return <React.Fragment key={'items-result--result-' + id}>
						<Result columnIdx={1} child={
							<>
								{true ? null : <Button
									icon='edit'
									intent='primary'
									minimal={true}
									small={true}
									disabled={true}
									onClick={() => openAddEditItemDialog(item)} />}
							</>
						} />
						<Result columnIdx={2} child={
							warehouse === undefined || warehouse === null ? '' : warehouse.title
						} />
						<Result columnIdx={3} child={
							item_item.ean
						} />
						<Result columnIdx={4} child={
							item_item.internal_code
						} />
						<Result columnIdx={5} child={
							item_item.title
						} />
						<Result columnIdx={6} child={
							getDocumentNumberForDocument(item.document_type, document)
						} />
						<Result columnIdx={7} child={
							document_type
						} />
						<Result columnIdx={8} child={
							<div title={payment_types_title} style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
								{ payment_types_title }
							</div>
						} />
						<Result columnIdx={9} child={
							supplier === undefined ? '' : (
								supplier.type == 'natural' ?
									supplier.surname + ' ' + supplier.name
									:
									supplier.company_name
							)
						} />
						<Result columnIdx={10} child={
							document_date === null ? '' : moment(document_date).format('DD.MM.YYYY')
						} />
						<Result columnIdx={11} cls='text-right' child={
							acquired_quantity == 0 ? '' :
								acquired_quantity
									.toLocaleString(
										undefined,
										{minimumFractionDigits: 2, maximumFractionDigits: 2}
									)
						} />
						<Result columnIdx={12} cls='text-right' child={
							spent_quantity == 0 ? '' :
								spent_quantity
									.toLocaleString(
										undefined,
										{minimumFractionDigits: 2, maximumFractionDigits: 2}
									)
						} />
						<Result columnIdx={13} cls='text-right font-bold' child={
							(current_stock?.real_count ?? 0)
								.toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								)
						} />
						<Result columnIdx={14} cls='text-right' child={
							item_price
								.toLocaleString(
									undefined,
									{minimumFractionDigits: 4, maximumFractionDigits: 4}
								)
						} />
						<Result columnIdx={15} cls='text-right' child={
							(current_stock?.price ?? 0)
								.toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								)
						} />
						<Result columnIdx={16} cls='text-right' child={
							(current_stock?.price_with_vat ?? 0)
								.toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								)
						} />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results-sum pb-4'>
			<div className='col'>
				Število zapisov: {table_items_sort_ids.length}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '60px' }}>
				{
					acquired_quantity_sum.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
				}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '60px' }}>
				{
					spent_quantity_sum.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
				}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '12px' }}>
				{/* spacer */}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '60px' }}>
				{
					'' //last_stock_count.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
				}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '60px' }}>
				{
					(() => {
						const price_with_count = Object.values(found_last_stock_data)
							.filter(x => x !== undefined)
							.reduce((prev, curr) => {
								return [ prev[0] + curr.price, prev[1] + curr.count ];
							}, [ 0, 0 ]);
						return price_with_count[1] == 0 ? 0 : (price_with_count[0] / price_with_count[1]);
					})()
						.toLocaleString(
							undefined,
							{minimumFractionDigits: 4, maximumFractionDigits: 4}
						)
				}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '12px' }}>
				{/* spacer */}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '80px' }}>
				{
					'' //last_price_with_vat.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
				}
			</div>
			<div className='col text-right font-bold' style={{ flex: 0, flexBasis: '80px' }}>
				{
					'' //last_stock_count.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
				}
			</div>
		</div>
	</>;
}

class StockInventoryDetailedDialog extends Component {
	constructor(props) {
		super(props);
		
		this.filterMainItems           = this.filterMainItems          .bind(this);
		this.mainFiltersValid          = this.mainFiltersValid         .bind(this);
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.setTableItemsSort         = this.setTableItemsSort        .bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		//this.compareItemsForSort       = this.compareItemsForSort      .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		this.exportToExcel             = this.exportToExcel            .bind(this);
		
		const table_items_filters = {
			internal_code:          '',
			document_types:         [],
			id_warehouses:          [],
			document_number:        '',
			ean:                    '',
			title:                  '',
			supplier_customer_name: '',
		};
		
		const state = {
			table_items_filters,
			table_items_sort_field:           'internal_code',
			table_items_sort_direction:       'ASC',
			main_filter__internal_code:       props.filter_internal_code,
			main_filter__document_date_range: props.filter_document_date_range,
			loading:                          true,
			detailed_stock_state:             null,
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			...state,
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			original_table_items_filters: {...table_items_filters},
			...state,
		};
		
		this.filterMainItems(this.state);
	}
	
	componentDidMount() {
		//loadDetailedStockInventoryItems(this.props.api_url, this.props.dispatch, this.props.token).then(() => {
		//	this.setState({ loading: false });
		//});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.items                != this.props.items ||
			prevState.detailed_stock_state != this.state.detailed_stock_state
		) {
			this.setState({
				...this.updateTableItemsFromProps(this.props, this.state),
			});
		}
	}
	
	filterMainItems(state) {
		state = state || this.state;
		if (!this.mainFiltersValid(state)) return;
		
		this.setState({ loading: true });
		
		loadDetailedStockInventoryItems(
			this.props.api_url,
			this.props.dispatch,
			this.props.token,
			true,
			state.main_filter__document_date_range[0] === null
				? ''
				: moment(state.main_filter__document_date_range[0]).format('YYYY-MM-DD'),
			state.main_filter__document_date_range[1] === null
				? ''
				: moment(state.main_filter__document_date_range[1]).format('YYYY-MM-DD'),
			state.main_filter__internal_code
		).then(stock => {
			console.log(Object.keys(stock));
			this.setState({
				loading: false,
				detailed_stock_state: stock,
			});
			//this.setState(
			//	this.updateTableItemsFromProps(this.props, state)
			//);
		});
	}
	
	mainFiltersValid(state) {
		if (state === undefined) return false;
		console.log({ state });
		
		return (
			state.main_filter__internal_code.trim().length >= 2 ||
			(state.main_filter__document_date_range[0] !== null && state.main_filter__document_date_range[1] !== null)
		);
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	getDocumentNumberForDocument(type, doc) {
		if (doc === null || doc === undefined) return 'n/a';
		
		if (type == 'invoice') {
			return doc.invoice_number_internal;
		}
		else if (type == 'stock_acquisition_document' || type == 'delivery_note') {
			return doc.document_number;
		}
		return null;
	}
	
	updateTableItemsFromProps(props, state) {
		console.log({
			aa: state.detailed_stock_state === null,
			bb: state.detailed_stock_state === null ? '' : (state.detailed_stock_state.item_documents === undefined),
		});
		if (state.detailed_stock_state === null || state.detailed_stock_state.item_documents === undefined) return state;
		
		const filter_internal_code       = state.main_filter__internal_code.trim().toUpperCase();
		const filter_document_date_start = state.main_filter__document_date_range[0] === null ? null : this.toIsoString(state.main_filter__document_date_range[0]);
		const filter_document_date_end   = state.main_filter__document_date_range[1] === null ? null : this.toIsoString(state.main_filter__document_date_range[1]);
		
		let items    = {};
		let ids      = [];
		let sort_ids = [];
		
		if (this.mainFiltersValid(state)) {
			items    = [];
			sort_ids = state.detailed_stock_state.item_document_sorted_ids;
			ids      = state.detailed_stock_state.item_document_sorted_ids;

			sort_ids = ids = ids.filter(id => {
				const item      = state.detailed_stock_state.item_documents[id];
				const item_item = props.items[item.id_item];
				
				// only show items
				if (item_item.type != 'item') return false;
				
				if (
					filter_internal_code.length > 0 &&
					item_item.internal_code.trim().toUpperCase() != filter_internal_code &&
					item_item.ean          .trim().toUpperCase() != filter_internal_code
				) {
					return false;
				}
				
				if (filter_document_date_start !== null && item.document_date !== null && item.document_date < filter_document_date_start) {
					return false;
				}
				if (filter_document_date_end !== null && item.document_date !== null && item.document_date > filter_document_date_end) {
					return false;
				}
					
				return true;
			});
			
			for (let i=0; i<ids.length; i++) {
				items[ids[i]] = state.detailed_stock_state.item_documents[ids[i]];
			}
		}
		
		const new_state = {
			table_items:                items,
			table_items_sort_ids:       sort_ids,
			table_items_filtered_ids:   ids,
			table_items_sort_field:     state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		console.log({ new_state });
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a   = '';
			let b   = '';
			let dir = direction;
			
			a = state.table_items[a_key][field];
			b = state.table_items[b_key][field];
			
			if (a == b) {
				a = state.table_items[a_key].document_date;
				b = state.table_items[b_key].document_date;
			}
			if (a == b) {
				a = state.table_items[a_key].ord;
				b = state.table_items[b_key].ord;
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_internal_code          = filters.internal_code         .trim().toUpperCase();
		const filters_document_types         = filters.document_types;
		const filters_id_warehouses          = filters.id_warehouses;
		const filters_document_number        = filters.document_number       .trim().toUpperCase();
		const filters_ean                    = filters.ean                   .trim().toUpperCase();
		const filters_title                  = filters.title                 .trim().toUpperCase();
		const filters_supplier_customer_name = filters.supplier_customer_name.trim().toUpperCase();
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item      = state.table_items[id];
			const item_item = this.props.items[item.id_item];
			if (item.id_item == '05660f12-f52a-452b-b780-29260f3c899c') {
				console.log({ item });
			}
			
			let document = item.document;
			
			if (filters_id_warehouses.length > 0) {
				let found = false;
				
				let warehouse = this.props.warehouses[document.id_warehouse];
				if (item.from_stock === true && document.stock_from_id_warehouse !== null) {
					warehouse = this.props.warehouses[document.stock_from_id_warehouse];
				}
				
				if (item.document_type == 'invoice') {
					// get items from invoice directly, since stock data is missing items for documents
					const doc_items = document.items;
					
					for (let i=0; i<doc_items.length; i++) {
						if (doc_items[i].id_item == item_item.id_item) {
							warehouse = doc_items[i].id_warehouse === null ? null : this.props.warehouses[doc_items[i].id_warehouse];
						}
					}
				}
				
				if (warehouse !== null && warehouse !== undefined) {
					for (let i=0; i<filters_id_warehouses.length; i++) {
						if (warehouse.id_warehouse == filters_id_warehouses[i]) {
							found = true;
							break;
						}
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_supplier_customer_name.length > 0) {
				const supplier = document === null || document === undefined || document.supplier_id_customer === null ?
					undefined
					:
					this.props.customers[document.supplier_id_customer];
				
				if (supplier !== null && supplier !== undefined) {
					const supplier_customer_name = supplier.type == 'natural' ?
						supplier.surname + ' ' + supplier.name
						:
						supplier.company_name;
					if (supplier_customer_name.toUpperCase().indexOf(filters_supplier_customer_name) == -1) {
						return false;
					}
				}
				else {
					return false;
				}
			}
			
			if (filters_ean != '' && item_item.ean.toUpperCase().indexOf(filters_ean) == -1) {
				return false;
			}
			
			if (filters_internal_code != '' && item_item.internal_code.toUpperCase().indexOf(filters_internal_code) == -1) {
				return false;
			}
			
			if (filters_title != '' && item_item.title.toUpperCase().indexOf(filters_title) == -1) {
				return false;
			}
			
			const document_number = this.getDocumentNumberForDocument(item.document_type, document)
			if (filters_document_number != '' && document_number.toUpperCase().indexOf(filters_document_number) == -1) {
				return false;
			}
			
			let document_type;
			if (item.document_type == 'invoice') {
				document_type = document.invoice_type;
			}
			else if (item.document_type == 'stock_acquisition_document') {
				document_type = 'stock-acquisition';
			}
			else if (item.document_type == 'delivery_note') {
				document_type = 'delivery-note-' + document.type;
			}
			if (document_type === undefined) {
				document_type = '';
			}
			if (filters_document_types.length > 0) {
				let found = false;
				for (let i=0; i<filters_document_types.length; i++) {
					if (document_type == filters_document_types[i] || item?.document?.type == filters_document_types[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			return true;
		});
		console.log({
			filtered_ids,
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			internal_code:          this.state.table_items_filters.internal_code,
			document_types:         this.state.table_items_filters.document_types,
			id_warehouses:          this.state.table_items_filters.id_warehouses,
			document_number:        this.state.table_items_filters.document_number,
			ean:                    this.state.table_items_filters.ean,
			title:                  this.state.table_items_filters.title,
			supplier_customer_name: this.state.table_items_filters.supplier_customer_name,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	exportToExcel() {
		const params = [];
		
		if (this.state.main_filter__document_date_range[0] !== null) {
			params.push(
				'date_start=' + moment(this.state.main_filter__document_date_range[0]).format('YYYY-MM-DD')
			);
		}
		if (this.state.main_filter__document_date_range[1] !== null) {
			params.push(
				'date_end=' + moment(this.state.main_filter__document_date_range[1]).format('YYYY-MM-DD')
			);
		}
		if (this.state.main_filter__internal_code.length > 0) {
			params.push(
				'internal_code=' + encodeURIComponent(this.state.main_filter__internal_code)
			);
		}
		
		params.push('document_types=' +         encodeURIComponent(this.state.table_items_filters.document_types.join(',')));
		params.push('id_warehouses=' +          encodeURIComponent(this.state.table_items_filters.id_warehouses .join(',')));
		
		params.push('document_number=' +        encodeURIComponent(this.state.table_items_filters.document_number       ));
		params.push('title=' +                  encodeURIComponent(this.state.table_items_filters.title                 ));
		params.push('supplier_customer_name=' + encodeURIComponent(this.state.table_items_filters.supplier_customer_name));
		
		params.push('version=new');
		
		window.open(
			this.props.api_url +
			'Warehouses/v1/export-detailed-stock-inventory-to-excel?' + 
			params.join('&')
		);
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			onClose={() => this.props.closeDialog()}
			className='stock-inventory-detailed-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Kartica artikla</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<span className={'horloader ' + (this.state.loading ? 'visible' : 'invisible')}></span>
					<div className='inner-body'>
						<div className='flex flex-grow flex-col'>
							<div className='flex flex-row main-filters'>
								<FormGroup
									className='mr-4'
									labelFor='stock-inventory-detailed-dialog--internal-code'>
									<TextWithTooltip
										label='Šifra artikla / črtna koda'
										tooltip='Šifra artikla / črtna koda' />
									<InputGroup
										id='stock-inventory-detailed-dialog--internal-code'
										value={this.state.main_filter__internal_code}
										onChange={event => {
											this.setState({ main_filter__internal_code: event.target.value });
										}} />
								</FormGroup>
								
								<FormGroup className='mr-4' style={{ width: 190 }}>
									<TextWithTooltip
										label='Datum dokumenta'
										tooltip='Datum dokumenta' />
									<DateRangeFilter
										filter_values={this.state.main_filter__document_date_range}
										original_start_date={null}
										original_end_date={null}
										onFilterChange={values => {
											this.setState({
												main_filter__document_date_range: values,
											});
										}} />
								</FormGroup>
							</div>
							<div className='flex justify-start'>
								<Button
									className='w-24'
									intent='primary'
									disabled={!this.mainFiltersValid(this.state) || this.state.loading}
									onClick={() => this.filterMainItems(this.state)}>
									Išči
								</Button>
								<Button
									className='w-32 ml-2'
									intent='primary'
									disabled={!this.mainFiltersValid(this.state) || this.state.loading}
									onClick={this.exportToExcel}>
									Izvoz v Excel
								</Button>
							</div>
							<div className='flex-1 stock-inventory-detailed-list'>
								<div className='flex-grow' style={{ flexBasis: '0' }}>
									{this.state.detailed_stock_state === null || this.state.detailed_stock_state.item_documents === undefined ||
										this.state.table_items_sort_ids === undefined
										? null
										: <Results
											table_items                 ={this.state.table_items}
											table_items_sort_ids        ={this.state.table_items_sort_ids}
											openAddEditItemDialog       ={this.openAddEditItemDialog}
											tableItemsFilters           ={this.state.table_items_filters}
											handleFilterChange          ={this.handleFilterChange}
											warehouses                  ={this.props.warehouses}
											items                       ={this.props.items}
											payment_types               ={this.props.payment_types}
											customers                   ={this.props.customers}
											stock_state                 ={this.state.detailed_stock_state}
											getDocumentNumberForDocument={this.getDocumentNumberForDocument} />
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
StockInventoryDetailedDialog.propTypes = {
	closeDialog: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		customers:      state.CodeTablesSlice.customers,
		items:          state.CodeTablesSlice.items,
		api_url:        state.CodeTablesSlice.api_url,
		warehouses:     state.CodeTablesSlice.warehouses,
		customers:      state.CodeTablesSlice.customers,
		payment_types:  state.CodeTablesSlice.payment_types,
		cash_registers: state.CashRegisterSlice.cash_registers,
		token:          state.UserSlice.token,
	};
}

export default connect(mapStateToProps)(StockInventoryDetailedDialog);
